<template>
  <div class="Acmusic">
    <audio
      autoplay
      :src="musicsrc"
      ref="musiccontrol"
      @canplay="getDuration"
      @timeupdate="changeDur"
    />
    <Acmusict />
    <a-tabs
      v-model:activeKey="activeKey"
      :tabBarStyle="{
        background: '#f78fad',
        color: 'white',
      }"
      @change="changediff"
    >
      <a-tab-pane key="1" tab="B级(+115)"></a-tab-pane>
      <a-tab-pane key="2" tab="A级(+155)"> </a-tab-pane>
      <a-tab-pane key="3" tab="S级(+200)"> </a-tab-pane>
    </a-tabs>
    <div class="">
      <div class="mb10">共{{ tablelist.length }}首歌曲</div>
      <a-table
        :columns="columns"
        :data-source="tablelist"
        @change="pagepation"
        :pagination="pagination"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'listest'">
            <div class="flexmode" v-if="record.url">
              <p class="point" @click="playmusics(record)">
                <play-circle-outlined />
              </p>
              <p class="point ml10" @click="downmuisc(record)">
                <download-outlined />
              </p>
            </div>
            <div v-else>
              伴奏推荐
              <a-button
                type="link"
                target="_blank"
                href="http://5sing.kugou.com/index.html"
                >5sing</a-button
              >
              酷狗 qq音乐
            </div>
          </template>
        </template>
      </a-table>
      <!-- <div class="pagination">
        <a-pagination
          v-model:current="pagination.current"
          :total="pagination.total"
          show-less-items
        />
      </div> -->
    </div>

    <div class="fixfoot">
      <div class="MusicControl flexmode hc">
        <div class="mLeft">
          <a-button type="dashed" shape="circle" size="default">
            <!-- <template #icon><StepBackwardOutlined /></template> -->
          </a-button>

          <a-button
            type="dashed"
            shape="circle"
            size="large"
            v-if="!isplay"
            @click="playmusic"
          >
            <template #icon><CaretRightOutlined /></template>
          </a-button>
          <a-button
            type="dashed"
            shape="circle"
            size="large"
            v-else
            @click="pausemusic"
          >
            <template #icon><PauseOutlined /></template>
          </a-button>
          <a-button type="dashed" shape="circle" size="default">
            <!-- <template #icon><StepForwardOutlined /></template> -->
          </a-button>
        </div>
        <div class="mMind">
          <div style="width: 450px" class="mpro">
            <a-slider
              v-model:value="defaultPercent"
              :disabled="sdru === 0"
              @change="mounceChange"
              :tip-formatter="null"
              @afterChange="onAfterChange"
              :style="{ top: '13px' }"
            />
          </div>
          <div class="mdru">
            <p>{{ nowduration + "/" + duration }}</p>
          </div>
        </div>
        <div class="mRight"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, ref } from "vue";
import {
  PlayCircleOutlined,
  CaretRightOutlined,
  PauseOutlined,
  DownloadOutlined,
} from "@ant-design/icons-vue";
// StepBackwardOutlined,
//   StepForwardOutlined,
import Musicdata from "./musicdata/musicdata";
import Acmusict from "../acmusict/index.vue";
// import axios from "axios";
export default {
  components: {
    PlayCircleOutlined,
    CaretRightOutlined,
    // StepBackwardOutlined,
    // StepForwardOutlined,
    PauseOutlined,
    DownloadOutlined,
    Acmusict,
  },
  setup() {
    const dataList = reactive({
      columns: [
        {
          title: "歌曲名",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "歌手",
          dataIndex: "muser",
          key: "muser",
        },
        {
          title: "试听",
          dataIndex: "listest",
          key: "listest",
        },
      ],
      tablelist: Musicdata.bmusicdata,
      smusicdata: Musicdata.smusicdata,
      amusicdata: Musicdata.amusicdata,
      bmusicdata: Musicdata.bmusicdata,
      musicsrc: "",
      duration: "00:00",
      nowduration: "00:00",
      pagination: {
        current: 1,
        total: Musicdata.bmusicdata.length,
        pageSize: 10,
      },
    });
    const defaultPercent = ref(0);
    const activeKey = ref("1");
    const sdru = ref(0);
    const snrur = ref(0);
    const audplay = ref(false);
    const musiccontrol = ref(null);
    const isplay = ref(false);

    function playmusics(val) {
      sdru.value = 0;
      defaultPercent.value = 0;
      audplay.value = false;
      dataList.musicsrc = val.url;
    }
    function pagepation(pagination) {
      dataList.pagination.current = pagination.current;
    }
    function downmuisc(val) {
      let a = document.createElement("a");
      a.target = "_blank";
      a.href = val.url;
      a.click();
      // axios({
      //   method: "post",
      //   url: val.url,
      //   responseType: "blob",
      // }).then((res) => {
      //   let blobUrl = window.URL.createObjectURL(res.data);
      //   let link = document.createElement("a");
      //   document.body.appendChild(link);
      //   link.style.display = "none";
      //   link.href = blobUrl;
      //   // 设置a标签的下载属性，设置文件名及格式
      //   link.download = `${val.name}.mp3`;
      //   // 自触发click事件
      //   link.click();
      //   document.body.removeChild(link);
      //   window.URL.revokeObjectURL(blobUrl);
      // });
    }
    function changediff(val) {
      dataList.pagination.current = 1;
      if (Number(val) === 1) {
        dataList.tablelist = dataList.bmusicdata;
        dataList.pagination.total = dataList.bmusicdata.length;
      } else if (Number(val) === 2) {
        dataList.tablelist = dataList.amusicdata;
        dataList.pagination.total = dataList.amusicdata.length;
      } else {
        dataList.tablelist = dataList.smusicdata;
        dataList.pagination.total = dataList.smusicdata.length;
      }
    }

    function playmusic() {
      if (sdru.value === 0) {
        return;
      }
      audplay.value = true;
      isplay.value = true;
      musiccontrol.value.play();
    }
    function pausemusic() {
      audplay.value = false;
      isplay.value = false;
      musiccontrol.value.pause();
    }
    // 获取时长
    function getDuration() {
      if (!audplay.value) {
        isplay.value = true;
      }
      audplay.value = true;
      sdru.value = musiccontrol.value.duration;
      dataList.duration = cduration(musiccontrol.value.duration);
    }
    // 实时播放
    function changeDur() {
      snrur.value = musiccontrol.value.currentTime;

      dataList.nowduration = cduration(musiccontrol.value.currentTime);
      figure();
    }
    // 计算进度条
    function figure() {
      defaultPercent.value = (snrur.value / sdru.value) * 100;

      if (defaultPercent.value === 100) {
        isplay.value = false;
      }
    }
    function mounceChange(value) {
      if (isplay.value) {
        isplay.value = false;
        musiccontrol.value.pause();
      }
      musiccontrol.value.currentTime = Math.round((value / 100) * sdru.value);
    }

    function onAfterChange(value) {
      musiccontrol.value.currentTime = Math.round((value / 100) * sdru.value);
      isplay.value = true;
      musiccontrol.value.play();
    }
    function cduration(val) {
      const min = Math.floor(val / 60);
      const sec = Math.floor(val % 60);

      return fillzero(min) + ":" + fillzero(sec);
    }

    function fillzero(val) {
      return val < 10 ? "0" + val : val.toString();
    }
    return {
      ...toRefs(dataList),
      activeKey,
      playmusics,
      changediff,
      sdru,
      snrur,
      musiccontrol,
      isplay,
      pausemusic,
      getDuration,
      changeDur,
      onAfterChange,
      mounceChange,
      figure,
      playmusic,
      downmuisc,
      defaultPercent,
      pagepation,
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep .ant-slider {
  // top: 13px;
}
.Acmusic {
  .fixfoot {
    background: black;
    padding: 10px;
    position: fixed;
    bottom: 25px;
    .MusicControl {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      .mLeft {
        width: 120px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 20px;
      }
      .mMind {
        width: 600px;
        display: flex;
        // justify-content: space-around;
        .mimg {
          // margin-right: 10px;
        }
        .mpro {
          margin: 0px 10px;
        }
        .mdru {
          color: white;
          position: relative;
          p {
            position: absolute;
            top: 5px;
          }
        }
      }
    }
  }
  .pagination {
    text-align: right;
  }
}
</style>

export default {
  smusicdata: [
    {
      name: "这，就是爱",
      muser: "张杰",
      url: "https://assets.floydzero.com/music/%E8%BF%99%2C%E5%B0%B1%E6%98%AF%E7%88%B1%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "着魔",
      muser: "张杰",
      url: "https://assets.floydzero.com/music/%E7%9D%80%E9%AD%94%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "够爱",
      muser: "东城卫",
      url: "https://assets.floydzero.com/music/%E5%A4%9F%E7%88%B1%E4%BC%B4%E5%A5%8F.mp3",
    },
    {
      name: "战争世界",
      muser: "姚贝娜",
      url: "https://assets.floydzero.com/music/smusic/%E6%88%98%E4%BA%89%E4%B8%96%E7%95%8C%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "我的世界",
      muser: "陈姿彤",
      url: "https://assets.floydzero.com/music/smusic/%E6%88%91%E7%9A%84%E4%B8%96%E7%95%8C%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "来自天堂的魔鬼",
      muser: "G.E.M.邓紫棋",
      url: "https://assets.floydzero.com/music/smusic/%E6%9D%A5%E8%87%AA%E5%A4%A9%E5%A0%82%E7%9A%84%E9%AD%94%E9%AC%BC%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "想你的365天",
      muser: "李玟",
      url: "https://assets.floydzero.com/music/smusic/%E6%83%B3%E4%BD%A0%E7%9A%84365%E5%A4%A9%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "拯救",
      muser: "孙楠",
      url: "https://assets.floydzero.com/music/smusic/%E6%8B%AF%E6%95%91%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "永远永远",
      muser: "李翊君",
      url: "https://assets.floydzero.com/music/smusic/%E6%B0%B8%E8%BF%9C%E6%B0%B8%E8%BF%9C%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "虹之间",
      muser: "金贵晟",
      url: "https://assets.floydzero.com/music/smusic/%E8%99%B9%E4%B9%8B%E9%97%B4%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "离歌",
      muser: "信乐团",
      url: "https://assets.floydzero.com/music/smusic/%E7%A6%BB%E6%AD%8C%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "煎熬",
      muser: "李佳薇",
      url: "https://assets.floydzero.com/music/smusic/%E7%85%8E%E7%86%AC%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "烟火里的尘埃",
      muser: "华晨宇",
      url: "https://assets.floydzero.com/music/smusic/%E7%83%9F%E7%81%AB%E9%87%8C%E7%9A%84%E5%B0%98%E5%9F%83%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "Let Me Be With You",
      muser: "ROUND TABLE",
      url: "https://assets.floydzero.com/music/smusic/let%20me%20be%20with%20you%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "Wake",
      muser: "Simply Worship",
      url: "https://assets.floydzero.com/music/smusic/wake%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "love story",
      muser: "Taylor Swift",
      url: "https://assets.floydzero.com/music/smusic/love%20story%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "月光",
      muser: "胡彦斌",
      url: "",
    },
    {
      name: "泡沫",
      muser: "G.E.M.邓紫棋",
      url: "",
    },
    {
      name: "破茧",
      muser: "张韶涵",
      url: "",
    },
    {
      name: "左手指月",
      muser: "萨顶顶",
      url: "",
    },
  ],
  amusicdata: [
    {
      name: "杀破狼",
      muser: "JS",
      url: "https://assets.floydzero.com/music/amusic/%E6%9D%80%E7%A0%B4%E7%8B%BC%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "哪里都是你",
      muser: "队长",
      url: "https://assets.floydzero.com/music/amusic/%E5%93%AA%E9%87%8C%E9%83%BD%E6%98%AF%E4%BD%A0%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "光的方向",
      muser: "张碧晨",
      url: "https://assets.floydzero.com/music/amusic/%E5%85%89%E7%9A%84%E6%96%B9%E5%90%91%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "隔岸",
      muser: "姚六一",
      url: "https://assets.floydzero.com/music/amusic/%E9%9A%94%E5%B2%B8%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "背对背拥抱",
      muser: "林俊杰",
      url: "https://assets.floydzero.com/music/amusic/%E8%83%8C%E5%AF%B9%E8%83%8C%E6%8B%A5%E6%8A%B1%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "光年之外",
      muser: "G.E.M.邓紫棋",
      url: "https://assets.floydzero.com/music/amusic/%E5%85%89%E5%B9%B4%E4%B9%8B%E5%A4%96%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "逆战",
      muser: "张杰",
      url: "https://assets.floydzero.com/music/amusic/%E9%80%86%E6%88%98%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "红昭愿",
      muser: "音阙诗听",
      url: "https://assets.floydzero.com/music/amusic/%E7%BA%A2%E6%98%AD%E6%84%BF%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "不良人",
      muser: "河图",
      url: "https://assets.floydzero.com/music/amusic/%E4%B8%8D%E8%89%AF%E4%BA%BA%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "就忘了吧",
      muser: "1k",
      url: "https://assets.floydzero.com/music/amusic/%E5%B0%B1%E5%BF%98%E4%BA%86%E5%90%A7%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "扉をあけて (打开心扉)",
      muser: "ANZA",
      url: "https://assets.floydzero.com/music/amusic/%E6%89%89%E3%82%92%E3%81%82%E3%81%91%E3%81%A6%20%28%E6%89%93%E5%BC%80%E5%BF%83%E6%89%89%29%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    // {
    //   name: "像小时候一样",
    //   muser: "郁可唯",
    //   url: "",
    // },
    {
      name: "忘记时间",
      muser: "胡歌",
      url: "https://assets.floydzero.com/music/amusic/%E5%BF%98%E8%AE%B0%E6%97%B6%E9%97%B4%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "一眼万年",
      muser: "S.H.E",
      url: "https://assets.floydzero.com/music/amusic/%E4%B8%80%E7%9C%BC%E4%B8%87%E5%B9%B4%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "起风了",
      muser: "qq音乐->买辣椒也用劵",
      url: "",
    },
    {
      name: "刚好遇见你",
      muser: "李玉刚",
      url: "",
    },
    {
      name: "春泥",
      muser: "庾澄庆",
      url: "",
    },
    {
      name: "彩色的黑",
      muser: "吉克隽逸",
      url: "",
    },
    {
      name: "探窗",
      muser: "国风新语/浮生梦/汐音社",
      url: "",
    },
  ],
  bmusicdata: [
    {
      name: "偏爱",
      muser: "张芸京",
      url: "https://assets.floydzero.com/music/bmusic/%E5%81%8F%E7%88%B1%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "山海入梦来",
      muser: "邹秋实",
      url: "https://assets.floydzero.com/music/bmusic/%E5%B1%B1%E6%B5%B7%E5%85%A5%E6%A2%A6%E6%9D%A5.mp3",
    },
    {
      name: "小城夏天",
      muser: "LBI利比",
      url: "https://assets.floydzero.com/music/bmusic/%E5%B0%8F%E5%9F%8E%E5%A4%8F%E5%A4%A9%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "静悄悄",
      muser: "陈泫孝",
      url: "https://assets.floydzero.com/music/bmusic/%E9%9D%99%E6%82%84%E6%82%84%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "天使",
      muser: "五月天",
      url: "https://assets.floydzero.com/music/bmusic/%E5%A4%A9%E4%BD%BF%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "勇气",
      muser: "棉子",
      url: "https://assets.floydzero.com/music/bmusic/%E5%8B%87%E6%B0%94%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "多幸运",
      muser: "韩安旭",
      url: "https://assets.floydzero.com/music/bmusic/%E5%A4%9A%E5%B9%B8%E8%BF%90%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "断点",
      muser: "张敬轩",
      url: "https://assets.floydzero.com/music/bmusic/%E6%96%AD%E7%82%B9%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "热爱105°的你",
      muser: " ",
      url: "https://assets.floydzero.com/music/bmusic/%E7%83%AD%E7%88%B1105%C2%B0%E7%9A%84%E4%BD%A0%28%E4%BC%B4%E5%A5%8F%29.mp3",
    },
    {
      name: "苦茶",
      muser: "棒棒堂/黑Gril",
      url: "",
    },
    {
      name: "灰色头像",
      muser: "许嵩",
      url: "",
    },
    {
      name: "你的承诺",
      muser: "海明威",
      url: "",
    },
    {
      name: "全世界宣布爱你",
      muser: "孙子涵/李潇潇",
      url: "",
    },
    {
      name: "三国杀",
      muser: "汪苏泷",
      url: "",
    },
    {
      name: "至少还有你",
      muser: "林忆莲",
      url: "",
    },
    {
      name: "小星星",
      muser: "汪苏伦",
      url: "",
    },
    {
      name: "是你",
      muser: "梦然",
      url: "",
    },
  ],
};

<template>
  <div class="Acmusict">
    <div>
      <h1>《繁星唱见》</h1>
      <p>这是一个小小的切片翻唱活动,能有10个人参加就好(qaq)</p>
    </div>
    <div>
      <h3>参与人员:</h3>
      <p>参与vup主播限制(小于等于35舰长)。</p>
    </div>
    <div>
      <h3>活动奖励:</h3>
      <p>第一名:为你摘星+100元直播间红包</p>
      <p>第二名:梦游仙境+52元直播间红包</p>
      <p>第三名:守护之翼+10元*2直播间红包</p>
      <p>优秀奖励:1个月舰长(4-7名切片点赞最高)</p>
    </div>
    <div>
      <h3>评比规则:</h3>
      <p>1.歌曲以切片的形式进行评比</p>
      <p>2.切片歌曲必须完整，为新录制切片(可以是直播切片或者私下单独录制)</p>
      <p>3.背景音乐必须使用伴奏</p>
      <p>4.以切片的(播放+点赞+投币+歌曲基础分)计为总分值</p>
      <p>5.选择限定歌曲(如下),分为(B级+115)(A级+155)(S级+200)</p>
    </div>
    <div>
      <h3>活动时间:(预计10月中旬)</h3>
      <p>报名时间:(待定)</p>
      <p>切片制作时间:(待定)</p>
      <p>切片投放时间:(待定)</p>
      <p>切片自然播放时间:(待定)</p>
      <p>最终分值统计时间:(待定)</p>
      <p>活动奖励发放时间:(待定)</p>
    </div>
    <div>
      <p>
        也不必须非要去挣第一,切也能帮你吸引观众,万一火了呢!(目前还在进行调研)
      </p>
    </div>
    <div class="mt10 mb10">
      <a-button type="link" @click="toapply">去报名-></a-button>
    </div>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    function toapply() {
      router.push("/acmusic/applypage");
    }

    return {
      toapply,
    };
  },
};
</script>
<style lang="scss" scoped>
.Acmusict {
  // text-align: center;
  background: linear-gradient(
    to top,
    rgb(249, 168, 212),
    rgb(216, 180, 254),
    rgb(129, 140, 248)
  );
  color: white;
  h1 {
    color: #ff4777;
  }
  h3 {
    color: #db5a6b;
  }
  div {
    margin: 0 auto;
    text-align: center;
  }
}
</style>
